@charset "utf-8";

@use '../../shared/scss/variable' as *;
@use '../../shared/scss/mixin' as *;
@use 'news_variable'  as *;
@use "sass:math";

/*-------------------------------------------
article
-------------------------------------------*/
.bl_article{
  opacity:0;
  margin:0 auto 60px;
  // max-width: 960px;
  max-width: 1200px;
  font-size:1.6rem;

  @include bpSP{
    min-height: 50vh;
    padding:0 4%;
    + .linkBtn{
      width:60vw;
    }
  }

  @include bpPC{
    min-height: 90vh;
    + .linkBtn{
      min-width:400px;
    }
  }

  &.is_loaded{
    animation: fadeInAnime 0.5s linear forwards;
    opacity:1;
  }

  &[data-type="latest"]{
    position:relative;
    max-height:200px;
    overflow: hidden;
    &::after{
      content:"";
      position:absolute;
      bottom:0;
      width:100%;
      height:100px;
      background: linear-gradient(transparent 0%,#ffffff 100% );
    }
  }

  .bl_headingWrap{
    &.-news{
      margin-bottom:20px;
      flex-direction: column;
      .bl_headingLv1{padding-bottom:0;}
      .itemRow{
        display:flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 15px;
      }
      @include bpPC{
        .bl_headingLv1{font-size:2.4rem;}
      }
    }
  }

  .date{
    text-align: right;
    margin-top:w(30);
    @include bpTablet{
      margin-top:w(60);
    }
    @include bpSP{
      font-size: 1.4rem;
      margin-top:sw(60);
    }
  }
  .title{
    font-size:clamp(2rem, 1.8vw, 2.8rem);
    font-weight:600;
    margin:w(30) 0 w(20);
    line-height: 1.4;
    text-align: center;
    @include bpTablet{
      margin-top:w(60);
    }
    @include bpSP{
      font-size: 2rem;
      margin:sw(30) 0 sw(15);
    }
  }
  .bl_headingLv1{
    padding-bottom:0;
    @include bpPC{
      font-size:2.4rem;
    }
  }

  .tags{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    li{
      font-size:1.2rem;
      line-height: 1.2;
    }
    li + li{
      margin-left:10px;
    }
    .cat,.subcat{
      background:#fff;
      padding:5px 15px 3px;
      border-radius: 3px;
    }
    .cat{
      color:palettes(blue, vivid);
      border: 1px solid palettes(blue, vivid);
    }
    .subcat{
      color:palettes(black, base);
      border: 1px solid palettes(black, base);
    }

    @include bpSP{
      li{
        margin-top:sw(5);
      }
      li + li{
        margin-left:10px;
      }
      .cat,.subcat{
        padding:3px 10px 2px;
        border-radius: 3px;
      }
    }
  }

  .meta{
    text-align: right;
    span{
      display: block;
    }
    @include bpSP{
      font-size: 1.4rem;
    }
  }

  .post{
    margin-top:w(50);
    @include bpSP{
      font-size: 1.4rem;
      margin-top:sw(30);
    }

    h2{
      font-size:2.4rem;
      font-weight:600;
      margin:w(57) 0 w(20);
      line-height: 1.4;
      .bl_headingLv1 + &{margin-top:0;}
      + h3{margin-top:0;}
      + p{margin-top:0;}
      @include bpTablet{
        margin-top:sw(30);
      }
      @include bpSP{
        font-size: 2rem;
        margin:sw(47) 0 sw(17);
        .bl_headingLv1 + &{margin-top:0;}
      }
    }

    h3{
      font-size:1.8rem;
      font-weight:600;
      margin:w(57) 0 w(10);
      line-height: 1.6;
      + p{margin-top:0;}
      + table{margin-top:1.5em;}
      @include bpTablet{
        margin:sw(22) 0 sw(5);
      }
      @include bpSP{
        margin:sw(37) 0 sw(10);
      }
    }

    h4{
      font-size:1.6rem;
      font-weight:600;
      margin:w(30) 0 w(10);
      line-height: 1.6;
      + p{margin-top:0;}
      @include bpTablet{
        margin:sw(10) 0 sw(5);
      }
      @include bpSP{
        margin:sw(20) 0 sw(10);
      }
    }
  
    p{
      // @include articleMargin1();
      + p{
        margin-top:1.5em;
      }

      a{
        text-decoration: underline;
        &:hover{
          text-decoration: none;
        }
      }
    }

    hr{
      @include articleMargin1();
    }
  
    table{
      border-top:palettes(border,base) 1px solid;
      border-left:palettes(border,base) 1px solid;
      width:100%;
      caption{
        margin:0;
        padding:10px 0;
        border:palettes(border,base) 1px solid;
        border-bottom: none;
        background:#eaeaea;
        text-align:center;
      }
      th,td{
        padding:18px;
        border-right:palettes(border,base) 1px solid;
        border-bottom:palettes(border,base) 1px solid;
        font-weight:normal;
        background:#fff;
      }
      th{
        background:#f4f4f4;
        text-align:left;
      }
      border-collapse: collapse;
      font-size:1.4rem;
      p{margin:0;}
      @include articleMargin1();
      @include bpPC{
        th,td{
          padding:10px;
        }
        tbody th{
          width:20%;
        }
      }
      @include bpTabletSP{
        th,td{
          padding:8px;
        }
      }
    }

    ol,ul{
      padding-left: 2em;
      margin:1.5em 0;
    }

    ul > li{
      list-style-type: disc;
      + li{
        margin-top: .5em;
      }
    }

    ol > li{
      list-style-position: outside;
      list-style-type: decimal;
      margin-left: 0;
      + li{
        margin-top: .5em;
      }
    }

    li p{
      margin:0;
    }

    em{
      font-style: italic;
    }

    .bl_imgWrap{
      margin:w(30) auto;
      figcaption{
        font-size:1.4rem;
        margin-top:cl(20);
      }
      @include bpTabletSP{
        margin:sw(30) auto;
        figcaption{
          font-size:1.2rem;
        }
      }
    }

    .bl_media{
      @include articleMargin1();
      .mediaImg{
        figcaption{
          text-align: center;
          font-size:1.4rem;
          margin-top:cl(20);
        }
      }
      .mediaBody{
        h2:first-child,
        h3:first-child{margin-top:0;}
      }

      @include bpPC{
        .mediaImg{
          max-width: calc((100% - 3.3%) / 2);
        }
      }
      @include bpTabletSP{
        .mediaImg{
          figcaption{
            font-size:1.2rem;
          }
        }
      }
    }

    .bl_cardUnit{
      @include articleMargin1();
      + .bl_cardUnit{
        margin-top:0;
      }
      .cardImg{
        figcaption{
          text-align: center;
          font-size:1.4rem;
          margin-top:cl(20);
        }
      }
      @include bpPC{
        .bl_card{
          margin-bottom:0;
        }
      }
      @include bpTabletSP{
        margin:0;
        .cardImg{
          figcaption{
            font-size:1.2rem;
          }
        }
      }
    }

    .el_btn{
      display:inline-block;
      padding:cl(12) cl(30);
      border:palettes(gray, dark) 1px solid;
      color:#333;
      text-align: center;
      @include hoverOpacity();
      &:hover,&:focus{
        background:palettes(black, base);
        color:#fff;
      }
    
      &.-stand{
        border-color:palettes(blue,base);
        background:palettes(blue,base);
        color:#fff;
        transition:all .5s $transition-ease;
        &:hover,&:focus{
          background:#fff;
          color:palettes(blue,base);
          opacity:1;
          &.-blank{
            &::after{color:palettes(blue,base);}
          }
        }
        &.-blank{
          &::after{color:#fff;}
        }
      }
    
      &.-white{
        border-color:#fff;
        background:#fff;
        color:palettes(blue,base);
        transition:all .5s $transition-ease;
        &:hover,&:focus{
          &.-blank{
            &::after{color:palettes(blue,base);}
          }
        }
        &.-blank{
          &::after{color:palettes(blue,base);}
        }
      }
    
      &.-wide{
        min-width:400px;
        @include bpSP{
          min-width:auto;
          width:auto;
        }
      }
    
      &.-blank{
        &::after{
          @include iconFont();
          content: "\e907";
          font-size:1.2rem;
          margin-left:8px;
          color:palettes(gray,dark);
          transition:all .3s;
        }
        &:hover,&:focus{
          &::after{color:#fff;}
        }
      }

    }

    .bl_btnWrap{
      display: flex;
      justify-content: center;
      margin:60px 0 0;
      &.-left{
        justify-content: flex-start;
      }
      @include bpPC{
        .el_btn + .el_btn{margin-left:20px;}
      }
      @include bpSP{
        margin:40px 0 0;
        flex-direction: column;
        align-items: center;
        .el_btn + .el_btn{margin-top:20px;}
      }
    }

    .bl_table{
      &.-half{
        @include bpPC{
          width:auto;
        }
      }
      &.-noBorder{
        font-size: 1.6rem;
        th,td{
          padding:3px 0;
        }
      }
    }

    > *:first-child{
      margin-top:0;
    }

    ._labelPDF::after,
    ._labelEXL::after,
    ._labelZIP::after,
    ._labelVIDEO::after,
    ._labelAUDIO::after{
      display:inline-block;
      padding:1px 8px;
      color:#fff;
      font-size:11px;
      line-height: 1.4;
      vertical-align: middle;
      margin:-2px 0 0 7px ;
    }

    ._labelPDF::after{content:'PDF'; background-color: #c90000;}
    ._labelEXL::after{content:'EXCEL'; background-color: #19792b;}
    ._labelZIP::after{content:'ZIP'; background-color: #666;}
    ._labelVIDEO::after{content:'VIDEO'; background-color: #118cdd;}
    ._labelAUDIO::after{content:'AUDIO'; background-color: #118cdd;}
  }

}

.bl_box{
  .bl_article[data-type="latest"]::after{
    background: linear-gradient(transparent 0%, palettes(blue,light) 100% );
  }
}

/*-------------------------------------------
importArticle
-------------------------------------------*/
.bl_importArticle{
  @include bpSP{
    font-size: 1.4rem;
  }

  .bl_headingWrap{
    &.-news{
      margin-bottom:20px;
      flex-direction: column;
      .bl_headingLv1{padding-bottom:0;}
      .itemRow{
        display:flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 15px;
      }
      @include bpPC{
        .bl_headingLv1{font-size:2.4rem;}
      }
    }
  }
  
  // for cmsod
  .bl_newsDetail{
    .bl_card{
      .cardImg{
        + .bclo-textWrap{
          // margin:40px 0 0;
          @include bpSP{
            // margin:20px 0 0;
          }
        }
      }
      .cardBody{
        &.bclo-textWrap{
          padding-top:0!important;
          margin:20px 0 0;
          .bclo-textWrap-title{margin-bottom:10px;}
          .bclo-link{margin-top:20px;}
        }
      }
      .btnWrap{
        text-align: left;
      }
    }
  
    .sectionWrap{
      margin:80px 0;
      &:first-child{margin-top:0;}
      @include bpSP{
        margin:60px 0;
      }
    }
  
    .btnWrap{
      margin-top:55px;
      + .btnWrap{margin-top:20px;}
      &.-center{
        text-align: center;
      }
      @include bpSP{
        margin-top:30px;
      }
    }
  
    .el_btn{
      .icon-blank{
        margin-left:8px;
        &::after{
          @include iconFont();
          content: "\e907";
          font-size:1.2rem;
          color:palettes(gray,dark);
          transition:all .3s;
        }
      }
      &:hover{
        .icon-blank::after{color:#fff;}
      }
    }
  
    .bl_media{
      align-items: flex-start;
      .mediaImg{
        width:calc(50% - 20px);
        img{
          width:auto;
          max-width:100%;
        }
        figcaption{
          text-align: center;
          margin-top: 10px;
        }
      }
      .mediaBody{
        .bclo-textWrap{margin-top:0;}
        .bclo-link{margin-top:15px;}
      }
  
      @include bpPC{
        &[class*="bclo-rightImage"]{
          .mediaImg{margin:0 0 0 40px}
        }
        &.bclo-leftImage-sm,
        &.bclo-rightImage-sm{
          .mediaImg{width:33%;}
        }
        .mediaImg{
          &.-thin{
            width:33%;
          }
        }
      }
  
      @include bpSP{
        .mediaImg{
          width:100%;
          margin:0 0 20px;
          &.-thin{
            width:100%;
          }
        }
        + .bl_media{
          margin-top:40px;
        }
        &[class*="bclo-rightImage"]{
          .mediaImg{margin-top:20px;}
        }
      }
    }
  
    .bclo-textWrap{
      margin:40px 0;
      &-title{
        font-size:2.4rem;
        font-weight: 600;
        line-height: 1.4;
        margin-bottom:30px;
      }
  
      &.-thin{
        max-width: 1080px;
        margin:60px auto;
      }
      @include bpSP{
        margin:30px 0;
        &-title{
          font-size:2rem;
          margin-bottom:15px;
        }
        &.-thin{
          max-width: 100%;
          margin:40px auto;
        }
      }
    }
  
    .bclo-col01,
    .bclo-col02{
      .cardImg{
        text-align: center;
      }
      .cardImg img{
        width:auto;
        max-width:100%;
        text-align: center;
      }
    }
  
    .bclo-col01{
      margin:0 0 80px;
      @include bpSP{
        margin:0 0 60px;
      }
    }
  
    .bclo-col03,
    .bclo-col04{
      .bclo-textWrap-title{
        @include bpPC{
          font-size:2rem;
        }
        @include bpTablet{
          font-size:1.8rem;
        }
      }
      .bclo-textWrap-text,
      .bclo-cap{
        @include bpTablet{
          font-size:1.4rem;
        }
      }
    }
  
    .bclo-imgLink{
      display:block;
      transition:.25s $transition-ease;
  
      &:focus,
      &:hover {
        opacity: .75;
        text-decoration: none;
      }
    }
  
    .bclo-cap{
      margin-top:15px;
      color:#333;
      @include bpSP{
        font-size:1.4rem;
        margin-top:10px;
      }
    }
  
    .bclo-title {
      &-lg {
        width: 100%;
        margin-bottom: 40px;
        font-size: 3.6rem;
        font-weight:600;
        color:#333;
        padding-top: 10px;
        @include bpSP {
          padding-top: 20px;
          margin-bottom: 40px;
          font-size: 2.4rem;
        }
      }
      &-sm {
        font-size: 2.4rem;
        font-weight:600;
        color:#333;
        margin-bottom:30px;
        @include bpSP { font-size: 1.8rem; }
      }
    }
  
    .bclo-list {
      margin: 30px 0;
  
      @include bpSP{
        padding:20px;
      }
  
      &-li {
        margin-bottom: 5px;
        font-size: 1.6rem;
  
        &-a {
          text-decoration: underline;
        }
      }
      &.ul-og {
        .mod-bclo-list-li { border-bottom: 1px dotted #7d7d7d; }
      }
  
      &.ul-disc{
        li{
          margin-left:1.5em;
          list-style-type: disc;
        }
      }
  
      &.ul-decimal{
        li{
          margin-left:1.5em;
          list-style-type: decimal;
        }
      }
      &.ul-latin{
        li{
          margin-left:1.5em;
          list-style-type: lower-roman;
        }
      }
      &.ul-upperLatin{
        li{
          margin-left:1.5em;
          list-style-type: upper-roman;
        }
      }
    }
  
    .bclo-leftImage-lg, .bclo-leftImage-sm, .bclo-rightImage-lg, .bclo-rightImage-sm {
      margin-top: 80px;
    }
  
    .bclo-free {
      margin-bottom: 40px;
      ol li{
        list-style-position: outside;
        list-style-type: decimal;
        margin-left: 2em;
      }
  
      ul li{
        list-style-position: outside;
        list-style-type: disc;
        margin-left: 1.6em;
      }
      
      table:not([border="0"]){
        border-collapse: collapse;
        th,td{
          padding:12px 15px;
          // border:#ccc 1px solid
        }
      }
      
      @for $num from 1 through 10 {
        table[border="#{$num}"]{
          th,td{
            border:#ccc #{$num}px solid;
          }
        }
      }
    }
  
    .cf_b_img_types{
      a:hover{
        text-decoration: none;
      }
    }
  
    .bclo-text{
      margin-bottom:40px;
    }
  
    .ta_right{text-align: right;}
    .ta_center{text-align: center;}
  
    .contactbtn{
      margin:30px auto 0;
      text-align: center;
      a{
        display:inline-block;
        padding:8px 30px;
        border:palettes(blue,base) 1px solid;
        background:palettes(blue,base);
        color:#fff;
        transition:all .5s $transition-ease;
        text-align: center;
        min-width:400px;
        @include hoverOpacity();
        &:hover{
          background:#fff;
          color:palettes(blue,base);
          opacity:1;
        }
  
        @include bpSP{
          min-width:auto;
          width:auto;
        }
      }
    }
  
    + .bl_btnWrap{margin-top:30px;}
  }

  // for Notes
  .bl_importNotesDB{

    font,
    pre{
      font-family: Helvetica, Arial, Tahoma, sans-serif;
      font-weight: 300;
    }

    /*---------------------------------------
    notes parts
    ----------------------------------------*/
    .newsDate{
      margin-bottom:20px;
      text-align:right;
      display: none;
    }
    .newsInc{
      text-align: right;
      margin-bottom:w(50);
    }
    .newsTitle{margin-bottom:30px; font-weight:bold; text-align:center; font-size:114%;display: none;}
    .textBlock{margin-bottom:25px;}
    .imgBlock1{margin-bottom:15px; text-align:center;}
    .imgBlock1 .caption{margin-top:5px; text-align:center;}
    .imgBlock2{overflow:hidden; zoom:1; margin:0 0 25px -10px; width:1080px;}
    .imgBlock2 .imgBox{width:320px; float:left; margin-left:150px;}
    .imgBlock2 .imgBox p,
    .imgBlock2 .imgBox img{text-align:center;}
    .imgBlock2 .imgBox .caption{margin-top:5px;}
    .btnIndex{text-align:center;}
    .textBold{font-weight:bold;}
    .inqBtn a,
    .btnIndex a{display:inline-block; padding:8px 30px 7px; background-color:#014099; color:#fff; font-weight:bold;}
    .inqBtn a:hover,
    .btnIndex a:hover{text-decoration:none; background-color:#00265b;}

    /* headingBlock01
    ------------------------*/
    .headingBlock01{font-size:128%; font-weight:bold; margin:20px 0 5px; padding:5px 10px; background-color:#eaeaea;}
    .headingBlock02{font-size:128%; font-weight:bold; color:#004097; margin-bottom:5px;}
    .headingBlock03{font-size:114%; font-weight:bold; margin-bottom:30px;}
    .headingBlock04{font-size:114%; font-weight:bold; margin-bottom:5px; padding-left:6px; border-left: 4px solid #004097;}

    /* careerList
    ------------------------*/
    .careerList{margin:5px 0 10px; padding-top:10px; border-top:#eaeaea 1px solid;}
    .careerList .releaseDate{margin-bottom:5px;}
    .careerList .textBold{margin-top:5px;}

    /* tableBlock01
    ------------------------*/
    .tableBlock01{margin-bottom:15px;}
    .tableBlock01 th,
    .tableBlock01 td{padding:5px 5px 5px 10px; border-bottom:5px solid #fff;}
    .tableBlock01 th{background-color:#e2e4e7; font-weight:normal; text-align:left;}
    .tableBlock01 td{background-color:#f3f3f3;}

    /* tableBlock01
    ------------------------*/
    .newsList a{text-decoration:none;}
    .newsList a,.newsList a:link{color:#004097;}
    .newsList a:visited{color:#4E98FB;}
    .newsList a:hover{text-decoration:underline;}

    /* newsList
    ------------------------*/
    /*.newsList dl{margin:5px 6px 0 14px;}*/
    .newsList dt{
      clear:both;
      float:left;
      padding:5px 0 0 15px;
      position:relative;
      margin-right:-300px;
      pointer-events: none;
      cursor:pointer;
    }
    .newsList dt span.date,
    .newsList dt span.corpName{
      display:inline-block;
      _display:inline;
      _zoom:1;
      line-height:13px;
      padding-right:11px;
    }
    .newsList dt img{padding-right:8px; vertical-align:middle;}
    .newsList dt a {display:block;}
    .newsList dd {border-bottom:#FFF 1px solid;}
    .newsList dd.even {}
    .newsList dd a {
      padding:2.2em 0 10px 15px;
      display:block;
      background-color:#fff;
    }
    .newsList dd.even a {background-color:#fafafa;}
    .newsList dd a:hover,.newsList dd a.hover {background-color:#e9f7fb;}

    /* pressList
    ------------------------*/
    .pressList dt{margin-top:15px;}
    .pressList dt span{margin-right:10px;}
    .pressList dt img{margin-right:8px; vertical-align:middle;}

    @media screen and (max-width:767px){
      .newsDate{margin-bottom:10px;}
      .newsInc{margin-bottom:sw(30);}
      .newsTitle{margin-bottom:15px;}
      
      .imgBlock1 img{max-width:100%;}
      .imgBlock2{width:100%; margin-left:0;}
      .imgBlock2 .imgBox{width:100%; float:none; margin:0 0 15px;}
      
      .newsList dt{padding-left:5px; padding-right:5px;}
      .newsList dd a{padding-left:5px;}
      .newsList dt img.label{width:66px; height:10px;}
      
      .pressList dt img.label{width:66px; height:10px;}
    }

    /* newslabel
    ------------------------*/
    *[class*="newslabel"]{display: inline-block; padding:2px 30px 0; margin-left:10px; font-size: 1.2rem; line-height: 1.4; vertical-align: middle;}
    .newslabelNew{background-color: #ed6942; color:#fff;}
    .newslabelSeg{background-color: #999; color:#fff;}

    @media screen and (max-width:767px){
      *[class*="newslabel"]{padding:2px 10px 0;}
    }



    .contactbtn {
      text-align: center;
      font-size: 1.8rem;
      margin-top:30px;
      padding: 30px 0 0;
      border-top: 1px solid #666;
    }
    .contactbtn a,
    .back_index a {
      display: block;
      width: 50%;
      padding:10px;
      color: #FFF;
      text-decoration: none;
      background: #004095;
      margin: 0 auto;
    }
    .contactbtn a:hover,
    .back_index a:hover {
      opacity: 0.8; /* 0.0～1.0 */
      filter: alpha(opacity=80); /* 0～100 */
    }
    .back_index{
      text-align: center;
      font-size: 1.8rem;
      margin-top:30px;
    }
    .back_index a{
      background-color:#fff;
      color:#014099;
      border:1px solid #014099;
    }

    //-------------------------------------------
    /* pressList
    ------------------------*/
    .pressListE dt{margin-top:15px;}
    .pressListE dt span{margin-right:10px; display:inline-block;}
    .pressListE dt img{margin-right:8px; vertical-align:middle;}

    @media screen and (max-width:767px){
      .pressListE dt img.label{width:66px; height:10px;}
    }

    //-------------------------------------------
    // RichText Area 1
    //-------------------------------------------
    .RBlock1 table{max-width:1080px;}
    .RBlock1 font {
      font-size:1.6rem;
      font-family: inherit;
      -webkit-text-size-adjust:none;
      }
    .RBlock1 td img{height:auto;}
    .RBlock1 table,
    .RBlock1 td,
    .RBlock1 th{
      border:1px solid #eeebea;
      padding:2px;}
      
    //-------------------------------------------
    // RichText Area 2
    //-------------------------------------------
    .RBlock2 table{max-width:1080px;}
    .RBlock2 font {
      font-size:1.6rem;
      -webkit-text-size-adjust:none;
      }
    .RBlock2 td img{height:auto;}
    .RBlock2 table,
    .RBlock2 td,
    .RBlock2 th{
      border:0px solid #eeebea;
      padding:2px;}

    //-------------------------------------------
    // Mobile
    //-------------------------------------------
    @include bpSP{
      .RBlock1 font{font-size:12px;}
      .RBlock1 table th,
      .RBlock1 table td{padding:0.5rem!important; line-height:1.2!important;}
      
      .RBlock2 font{font-size:12px;}
      .RBlock2 table th,
      .RBlock2 table td{padding:0.5rem!important; line-height:1.2!important;}
    }

  }
}